import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import TestimonialsStyleOne from '../components/Testimonials/TestimonialsStyleOne'
import TagsForAll from '../components/Common/TagsForAll'
import { Helmet } from 'react-helmet'


const Testimonials = () => {
    return (
        <Layout>
        <Helmet>
            <TagsForAll pageTitle="Testimonials" />
            <meta name='robots' content='index, follow' />
            <link rel="canonical" href="https://zaavia.net/testimonials/" />
        </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Testimonials" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Testimonials" 
            />
            <TestimonialsStyleOne />
            
            <Footer />
        </Layout>
    );
}

export default Testimonials