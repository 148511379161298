import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'
import client3 from '../../assets/images/testimonials/client3.jpg'
import client4 from '../../assets/images/testimonials/client4.jpg'
import client5 from '../../assets/images/testimonials/Dr. Said Abdou.jpeg'
import client6 from '../../assets/images/testimonials/Dr. Osama al-Fayoumi.jpeg'
import client7 from '../../assets/images/testimonials/Dr. Abdul Malek.jpeg'
import client8 from '../../assets/images/testimonials/Mr. Sami Faisal.jpeg'

const TestimonialsStyleOne = () => {
    return (
        <div className="testimonials-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Testimonials
                    </span>
                    <h2>What Our Clients are Saying?</h2>
                    <p>"Excellent firms don’t believe in excellence – only in constant improvement and constant change.” –Tom Peters</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>Dr. Ziauddin Group of Hospitals is one of the largest private groups in the country. Its mission has been to reach out to the masses and cater to their need for quality health care facilities at affordable rates. It began with a small maternity home and has grown exponentially over the years to include five hospitals in total, including three tertiary care facilities and a cancer care centre.
                            Panacea enabled us to move from the cumbersome and error prone manual system to the automated paperless electronic system, enabling us to deliver safer blood, without compromising on throughput. We have been able to implement international blood banking standards at our facilities, providing quality health care to our patients.
                            We opted for Zaavia's "Panacea with Cloud" subscription package specially to relieve our IT infrastructure of any further added load. The cloud server service has been reliable, with literally negligible downtimes.
                            Panacea now operates behind the scenes, as an integral part of our own services.
                            </p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client1} alt="about" />
                                    <div className="title">
                                        <h3>Dr Arif Hussain</h3>
                                        <span>Director at Clinical Laboratories and Blood Bank</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>The Indus Hospital, established in 2007 was Pakistan's first paperless and 100% cashless, state-of-the-art, tertiary care hospital. All medical services including high-end surgical procedures are provided indiscriminately to everyone in need, and rendered absolutely "Free of Cost", with dignity. It has dealt with over 2.1 million patients to date.
                            The Indus Hospital Blood Centre was the first centralised blood centre established in Pakistan, in 2013. The Blood Banking Management Information System, Panacea, was provided by Zaavia. Since its deployment, blood donations through drives have grown over 30% annually, enabling us to increase the number of partner hospitals. Panacea enables us to host completely paperless blood drives spanning over 3527 square kilometers of Karachi, covering a population of 20 million.
                            We have now deployed Panacea in 1 regional blood centre in Karachi, and 3 hospital blood banks in rural areas of Pakistan. Given the remote location of the hospital blood banks, our fears of monitoring quality, in a human resource compromised environment, have been allayed by the error trapping and reporting functionality of Panacea.
                            Our experience with Panacea and the Zaavia team, has resulted in our decision to deploy Panacea at all our new centres stretching across Sindh and Punjab.</p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client2} alt="about" />
                                    <div className="title">
                                        <h3>Prof. Abdul Bari Khan </h3>
                                        <span>CEO at The Indus Hospital Karachi, Pakistan</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>National Institute of Blood Disease and Bone Marrow Transplantationis a specialised centre of Haematology, Bone Marrow Transplantation and Allied Surgical/Medical Specialties in the heart of the city of Karachi. It is the only tertiary care and teaching institute in Pakistan, exclusively dedicated to providing comprehensive diagnosis and management of patients suffering from blood disorders.
                                With our state of the art services, we required a commensurate Blood Banking Management Information System (BBMIS), ensuring the quality of the blood transfusion services. The inventory status screen on its own convinced us that this is exactly what we needed. With all the important blood unit information on one screen, further layered with details at a touch, indicated that Panacea has been designed and developed by haematologists who understood the practical issues in blood banks today.
                                The deployment of Panacea was performed seamlessly, as was the training. The online 24/7 Zaavia support has kept our services up and running, minimizing down time, without the need for onsite visits. Panacea has complemented the performance of our human resource, and further elevated the quality of our transfusion services.
                            </p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client3} alt="about" />
                                    <div className="title">
                                        <h3>Prof. Tahir S. Shamsi</h3>
                                        <span>MBBS, FCPP, MRCPath, FRCPath. Professor of Haematology
                                                Consultant Haematologist & Transplant Physician
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>The Government of Pakistan is implementing Safe Blood Transfusion Programme with the technical (GIZ) and financial assistance (KfW — German Development Bank) of Government of Germany. Under the SBT project, 10 modern regional blood centers were established and 59 hospital based blood bank re-furbished across Pakistan.
                                A comprehensive blood bank management information system (BBMIS) complying to international safety standards was procured from Zaavia - a software house in Karachi, Pakistan to support the functioning of 10 Regional Blood Centers through international competitive bidding.
                                We found Zaavia to be completely professional in the tender of the contract, understanding fully the requirements, and being fully transparent of what they could provide. They have not only satisfied our tender requirements, but exceeded them.
                                Their commitment to deployment timescales was impressive. As each regional blood centre building was ready, so was Zaavia, deploying within the prescribed timescales.
                                Further, they provided first rate training to the blood centre personnel, which is an accomplishment in itself in a compromised human resource environment in developing countries. Their post deployment 24/7 online support has also been first rate.
                                Until August 2016, Zaavia has successfully completed deployment and training in 4 out of 10 regional blood centres, and we have no doubt that their performance will be just as good as the next blood centres come online.
                                
                                Based on their current performance, we will always welcome tenders from Zaavia in any of our future projects.

                             </p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client4} alt="about" />
                                    <div className="title">
                                        <h3>Prof. Dr. Hasan Abbas Zaheer </h3>
                                        <span>National Coordinator</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>"Our experience in implementing BBIS (Panacea) was a great challenge as we start this project in a difficult time where the pandemic (COVID-19) is changing world. However, Alfal co. & Zaavia team with their endless support in every step we take they made this possible through online classes (Zoom), WhatsApp Groups, etc.  
                                Ever since starting, reaching out to support is like reaching out to an old friend. Every time you need support Panacea team takes ownership of the problem in such a manner you realize they actually care about fixing the issue for you. 
                                Panacea BBIS provides less chance of medical errors due to transmission and interpretation errors as interfacing with various equipment. Additionally, it provides variety of informative admin reports from vein-to-vein process from Blood Bank module to transfusion module. We had our 1st CAP Accreditation we can't imagine going back to the old system, couldn't be more satsatisfied.

                             </p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="title">
                                        <h3>Mohamed Abneuf</h3>
                                        <span>Laboratory Specialist MLS(ASCPi)CM
                                                Lab. Quality and Safety Officer
                                                Saudi German Hospital Aseer
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>Fatimid Foundation Blood Bank and Haemotological services is established in 1980.In present 9 centre are running all over Pakistan.
                                A comprehensive blood bank management information system complying to international safety standard,was procured from Zaavia software house in Karachi Pakistan to support the functioning of our 9 centre of Fatimid foundation through Pakistan competitive buildup.
                                We found zaavia to be completely professional in tender of contract,understanding fully requirement and being fully transparent of what they could provide. They have not only satisfied our tender requirement, but exceed them.
                                Further, they provide full training to our staff, which is an accomplishment in its self in a compromising human resource environment in developing countries.
                                Their post deployment 24 hours service online support has also been first rate. We have no doubt that their performance will be just as good as the next our blood banks centres come on line.
                                Based current performance, we will always welcome tenders from zaavia in any of our future project.

                             </p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="title">
                                        <h3>Dr. Azizuddin Qamruddin</h3>
                                        <span>Director,
                                        Blood Management Department,
                                        Fatimid foundation,
                                        Karachi.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>PANACEA blood bank module is friendly software have various reporting functions that help us to pass both AABB and CAP without citations
                             </p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client5} alt="about" />
                                    <div className="title">
                                        <h3>Dr. Said Abdou, </h3>
                                        <span>Lab Director, SGH Dammam
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>
                                Reduce and eliminate errors , Organize and manage the blood bank workflow, Safe and secure storage.
                                Executive management reports and statistics panel.
                                Technical support at the highest level of attention and customer satisfaction  , Fully compatible with CAP, AABB, it can be said in short an integrated system with global professionalism.
                            </p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client6} alt="about" />
                                    <div className="title">
                                        <h3>Dr. Osama al-Fayoumi</h3>
                                        <span>Director - Blood Bank, SGH - Medina
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>
                                Panacea is a perfect LIS program for the blood bank. From vein of the donor to the vein of the patient.
                            </p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client7} alt="about" />
                                    <div className="title">
                                        <h3>Dr. Abdul Malek</h3>
                                        <span>HOD Blood Bank - SGH Jeddah
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="single-testimonials-item">
                            <p>
                            One of the best features on the system for us as supervisors is monitoring and auditing workflow, which helps in the development processes for serving patients and employees. Through the professional reports available on the system.
                            </p>
                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <img src={client8} alt="about" />
                                    <div className="title">
                                        <h3>Mr. Sami Faisal</h3>
                                        <span>Supervisor Blood Bank - SGH - Medina
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TestimonialsStyleOne



